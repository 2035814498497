import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "../privacy-policy.css"

// Components
import IndexLayout from "../../layouts";
import Page from "../../components/Page";

// // Images and icons
import CurveBG from "../../assets/images/curve-bg.svg";

const PrivacyPolicy = ({ data }: any) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-policy">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${CurveBG}')` }}
          ></div>

          <div className="main-container">
            <div className="policy-hero">
              <h1>Privacy Policy</h1>
            </div>
            <div className="section-content-wrapper">
              <div className="policy-wrapper">
                <div className="markdown-content">
                  <div
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>

              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default PrivacyPolicy;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
